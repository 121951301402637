<div class="content">
    <ion-row>
        <ion-col style="
          height: 80vh;
          place-items: center;
          display: grid;
        " size="12">
            <h2>{{ "resetForgottenPasswordMessage" | translate | capitalizeFirstLetter }} </h2>
            <p [innerHTML]="'passwordValidationRules' | translate"></p>
            <div [formGroup]="resetForgottenPasswordForm">
                <!-- Password field -->
                <mat-form-field appearance="outline" color="accent" style="width: 100%">
                    <mat-label>{{ "newPassword" | translate | capitalizeFirstLetter }}</mat-label>
                    <input matInput type="password" [formControlName]="'password'" />
                </mat-form-field>
                <br />
                <mat-form-field appearance="outline" color="accent" style="width: 100%">
                    <mat-label>{{ "confirmPassword" | translate | capitalizeFirstLetter }}</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm" />
                </mat-form-field>
                <p style="color: tomato;" *ngIf="resetForgottenPasswordForm.hasError('notSame') && f.passwordConfirm">{{
                    'passwordError' | translate | capitalizeFirstLetter }}</p>
                <button mat-button  [disabled]="resetForgottenPasswordForm.invalid"
                    (click)="resetPassword()" submit style="
                min-width: 100%;
                min-height: 36px;
                border-radius: 0px;
                text-align: -webkit-center;
              ">
                    <span *ngIf="!isLoading">{{ "confirm" | translate | capitalizeFirstLetter }}</span>
                    <mat-progress-spinner *ngIf="isLoading" [diameter]="24" style="display: block;" [mode]="'indeterminate'" color="accent">
                    </mat-progress-spinner>
                </button>


            </div>
        </ion-col>
        <mat-divider> </mat-divider>
    </ion-row>
</div>