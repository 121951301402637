import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResetForgottenPasswordComponent } from './reset-forgotten-password.component';

const routes: Routes = [{ path: '', component: ResetForgottenPasswordComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResetForgottenRoutingModule {}
