import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-reset-forgotten-password',
  templateUrl: './reset-forgotten-password.component.html',
  styleUrls: ['./reset-forgotten-password.component.scss']
})
export class ResetForgottenPasswordComponent implements OnInit {

  resetForgottenPasswordForm: UntypedFormGroup;
  isLoading = false;

  uidb64: string;
  token: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private _sharedService: SharedService,
    private _formBuilder: UntypedFormBuilder
  ) { 
    
  }

  get f() { return this.resetForgottenPasswordForm.value; }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const passwordConfirm = group.get('passwordConfirm').value;
    return password === passwordConfirm ? null : { notSame: true }
  }

  ngOnInit(): void {
    this.uidb64 = this.route.snapshot.paramMap.get('uidb64');
    this.token = this.route.snapshot.paramMap.get('token');

    this.resetForgottenPasswordForm = this._formBuilder.group({
      password: ['', [Validators.minLength(6), Validators.required]],
      passwordConfirm: ['', [Validators.required]],

    }, { validators: this.checkPasswords });
  }

  resetPassword() {
    if (this.f.password == this.f.passwordConfirm) {
      this.isLoading = true
      this._graphql.resetForgottenPassword(this.f.password, this.uidb64, this.token)
        .pipe(
          take(1),
          finalize(() => this.isLoading = false))
        .subscribe((_) => {
          this._sharedService.makeToast(this._translate.instant("resetPasswordSuccess"),"","success")
          this.router.navigateByUrl('/')
        },
          async error => {
            this._sharedService.makeToast(this._translate.instant("resetPasswordFail"),"","danger")

          }
        )

    }

  }

}
