import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ResetForgottenPasswordComponent } from './reset-forgotten-password.component';
import { ResetForgottenRoutingModule } from './reset-forgotten-password-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [ResetForgottenPasswordComponent],
  imports: [
    CommonModule, 
    FormsModule,
    ResetForgottenRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResetForgottenPasswordModule {}
